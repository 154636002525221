var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"justify-content-center"},[_c('h1',[_vm._v("Plans")]),_c('b-col',{attrs:{"md":"12"}},[_c('datatable',{ref:"datatable",attrs:{"classe":"table table-bordered table-striped","url":_vm.datatableUrl,"btnExport":false,"btnCreate":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var actions = ref.actions;
return _c('div',{},[_c('b-dropdown',{attrs:{"boundary":"viewport","size":"sm","text":"Actions","variant":"primary"}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push({
                name: 'plans-edit',
                params: { id: actions.id },
              })}}},[_c('i',{staticClass:"fa fa-edit p-1"}),_vm._v(" Edit ")])],1)],1)}},{key:"status",fn:function(ref){
              var data = ref.data;
return [_c('small',{class:'badge badge-' + (data == 'E' ? 'primary' : 'danger')},[_vm._v(" "+_vm._s(data == "E" ? "Enabled" : "Disabled")+" ")])]}},{key:"payment_cycle",fn:function(ref){
              var data = ref.data;
return [_c('small',{class:'badge badge-' + (data == 'Y' ? 'success' : 'info')},[_vm._v(" "+_vm._s(data == "Y" ? "Yearly" : "Monthly")+" ")])]}},{key:"value",fn:function(ref){
              var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.decimalToCurrency(data))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }