<template>
  <b-row class="justify-content-center">
    <h1>Plans</h1>
    <b-col md="12">
      <datatable
        classe="table table-bordered table-striped"
        :url="datatableUrl"
        ref="datatable"
        :btnExport="false"
        :btnCreate="false"
      >
        <div slot-scope="{ actions }">
          <b-dropdown boundary="viewport" size="sm" text="Actions" variant="primary">
            <b-dropdown-item
              @click="
                $router.push({
                  name: 'plans-edit',
                  params: { id: actions.id },
                })
              "
            >
              <i class="fa fa-edit p-1"></i> Edit
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <template #status="{ data }">
          <small :class="'badge badge-' + (data == 'E' ? 'primary' : 'danger')">
            {{ data == "E" ? "Enabled" : "Disabled" }}
          </small>
        </template>
        <template #payment_cycle="{ data }">
          <small :class="'badge badge-' + (data == 'Y' ? 'success' : 'info')">
            {{ data == "Y" ? "Yearly" : "Monthly" }}
          </small>
        </template>
        <template #value="{ data }">
          {{ decimalToCurrency(data) }}
        </template>
      </datatable>
    </b-col>
  </b-row>
</template>
<script>
import PlansService from "features/settings/services/PlansService";
import { resource } from "helpers";

export default {
  name: "Plan",
  data: () => ({
    datatableUrl: PlansService.baseUrl,
    resource,
  }),

  methods: {
    decimalToCurrency(value) {
      return Number(value).toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
      });
    },
    changeStatus(currentStatus, id) {
      this.$swal({
        title: "Are you sure?",
        text: "This action changes the item status.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          PlansService.changeStatus(currentStatus === "E" ? "D" : "E", id).then(() => {
            this.$refs.datatable.refresh();
            this.$swal("Changed!", "Status changed", "success");
          });
        }
      });
    },
  },
};
</script>
